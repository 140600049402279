/* global $ */

class ContentSwitcherNav {
  constructor(switcher, $el) {
    this.switcher   = switcher;
    this.$el        = $el;
    this.btns       = [];
    this.isFixed    = false;

    if(this.$el.hasClass('ContentSwitcherNav--fixed')) {
      this.isFixed = true;
    }

    this.$el.find('.ContentSwitcherNavItemBtn').each((i, e) => {
      this.btns.push($(e));
    });
    this.$el.find('.ContentSwitcherNavItemBtn').on('click', (e) => {
      this.switcher.select($(e.currentTarget).attr('data-target'));
    });

    this.btns[0].addClass('-current');
  }

  fadein() {
    if(this.isFixed) this.$el.addClass('-fadein');
  }

  fadeout() {
    if(this.isFixed) this.$el.removeClass('-fadein');
  }

  select(target) {
    for(let i = 0; i < this.btns.length; i++) {
      if(this.btns[i].attr('data-target') == target) {
        this.btns[i].addClass('-current');
      } else {
        this.btns[i].removeClass('-current');
      }
    }
  }
}

class ContentSwitcher {
  constructor(mainApp) {
    this.mainApp      = mainApp;
    this.$container   = $('.ContentSwitcher');
    this.blocks       = [];
    this.navBtns      = [];
    this.navSet       = [];
    this.isDarkmode   = false;
    this.hasPadding   = false;

    if(this.$container.hasClass('ContentSwitcher--gallery')) {
      this.hasPadding = true;
    }

    this.$container.find('.ContentSwitcherNav').each((i, e) => {
      this.navSet.push(new ContentSwitcherNav(this, $(e)));
    });

    this.$container.find('.ContentSwitcher__block').each((i, e) => {
      this.blocks.push($(e));
    });

    this.blocks[0].css('display', 'block');
  }

  onWindowScroll(scrollTop) {
    if(
      scrollTop > parseInt(this.$container.offset()['top'] - (this.mainApp.windowHeight * 0.5))
      && scrollTop < parseInt(this.$container.offset()['top'] + this.$container.height() - (this.mainApp.windowHeight * 0.5))
    ) {
      if(this.isDarkmode) {
        $('body').addClass('darkmode');
      }
      for(let i = 0; i < this.navSet.length; i++) {
        this.navSet[i].fadein();
      }
    } else {
      $('body').removeClass('darkmode');
      for(let i = 0; i < this.navSet.length; i++) {
        this.navSet[i].fadeout();
      }
    }
  }

  select(target) {
    if(target == 'night') {
      $('body').addClass('darkmode');
      this.isDarkmode = true;
    } else {
      $('body').removeClass('darkmode');
      this.isDarkmode = false;
    }

    if(!this.hasPadding) {
      this.mainApp.scrollPage($('#ContentSwitcherBase').offset()['top']);
    }

    for(let i = 0; i < this.navSet.length; i++) {
      this.navSet[i].select(target);
    }

    let currentItem = undefined;
    for(let i = 0; i < this.blocks.length; i++) {
      if(this.blocks[i].attr('data-season') == target) {
        currentItem = this.blocks[i];
        break;
      }
    }

    if(currentItem != undefined) {
      let tmpHeight = this.$container.outerHeight();
      this.$container.css('height', tmpHeight);

      for(let i = 0; i < this.blocks.length; i++) {
        if(this.blocks[i].attr('data-season') == target) {
          this.blocks[i].css({'display':'block', 'opacity':0}).animate({'opacity': 1}, 1000, 'easeInOutSine');
        } else {
          this.blocks[i].css('display', 'none');
        }
      }

      setTimeout(() => {
        let targetHeight = parseInt(currentItem.height());
        if(this.hasPadding) targetHeight += 180;
        this.$container.stop(false).animate({'height':targetHeight}, 1000, 'easeInOutSine', () => {
          this.$container.css('height', 'auto');
        });
      }, 30);


    }
  }
}

export default ContentSwitcher;
