/* global $ */

class NewsListItem {
  constructor($container) {
    this.$window      = $(window);
    this.$container   = $container;
    this.$body        = this.$container.find('.NewsListItemBody');
    this.$head        = this.$container.find('.NewsListItemHead');
    this.$inner       = this.$container.find('.NewsListItemBody__inner');
    this.$btnClose    = this.$container.find('.NewsListItemBody__close');
    this.mediaQuery   = window.matchMedia('(max-width: 750px)');
    this.isOpen       = false;
    this.onCompleteClose = true;

    this.defaultHeight = 27;

    if(this.mediaQuery.matches) {
      this.$body.css({'overflow':'hidden', 'height': 0});
    } else {
      this.$body.css({'overflow':'hidden', 'height': 27});
    }

    this.$container.on('click', () => {
      /*
      if(!this.onCompleteClose) {
        e.preventDefault();
        return false;
      }
      */
      this.onContainerClick();
    });

    this.$head.on('click', () => {
      /*
      if(this.mediaQuery.matches) {
        if(this.isOpen) {
          this.close();
        } else {
          this.open();
        }
      }
      */
      if(this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    });

    this.$btnClose.on('click', () => {
      /*
      if(this.mediaQuery.matches) {
        if(this.isOpen) {
          this.close();
        }
      }
      */
      if(this.isOpen) {
        this.close();
      }
    });

    this.$window
      .on('resize', ()=>{
        this.onWindowResize();
      });
    // this.onWindowResize();
  }
  onContainerClick() {
    if(!this.isOpen) {
      console.log('onContainerClick');
      if(!this.mediaQuery.matches) {
        this.open();
        this.$container.off('click');
      }
    }
  }

  open() {
    this.onCompleteClose = false;
    if(!this.isOpen) {
      this.$container.addClass('NewsListItem--open');
      this.isOpen = true;
      this.$body.stop(false).animate({'height':this.$inner.height()}, 400, 'easeOutExpo', () => {
        this.$body.css('height', 'auto');
      });
    }
  }

  close() {
    this.onCompleteClose = false;
    let closeHeight = 27;
    if(this.mediaQuery.matches) closeHeight = 0;
    if(this.isOpen) {
      this.isOpen = false;
      this.$body.css({
        'height': this.$inner.height(),
        'overflow': 'hidden'
      }).delay(10)
      .animate({'height':closeHeight}, 400, 'easeOutExpo', () => {
        this.$container.removeClass('NewsListItem--open');
        this.onCompleteClose = true;

        this.$container.on('click', () => {
          this.onContainerClick();
        });
      });
    }
  }

  onWindowResize() {
    this.isOpen = false;
    if(this.mediaQuery.matches) {
      this.$body.stop(false).css({
        'height': 0,
        'overflow': 'hidden'
      });
    } else {
      this.$body.stop(false).css({
        'height': 'auto',
        'overflow': 'visible'
      });
    }
  }
}
export default NewsListItem;
