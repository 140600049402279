/* global $ */
/* global ga */
/* global FONTPLUS */

import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
barba.use(barbaPrefetch);



class Router {
  constructor(mainApp) {
    this.mainApp            = mainApp;
    this.currentNamespace   = '';
    this.pageApp = undefined;


    barba.init({
      timeout: 3000,
      debug: false,
      transitions: [{
        name: 'default',
        beforeOnce : () => {
        },
        once : (data) => {
          this.currentNamespace = data['next']['namespace'];
        },
        afterOnce : (data) => {
          this.checkPageApp(data);
          this.mainApp.initPageApp(this.currentNamespace);
          /*
          this.checkPageApp(data);
          setTimeout(() => {
            this.mainApp.checkPageApp();
          }, 50);
          */
        },
        before: async () => {
          let waitDuration = 0;
          this.mainApp.destroy();

          if($('body').hasClass('darkmode')) {
            $('body').removeClass('darkmode');
          }

          await new Promise((resolve) => {
            return setTimeout(resolve, waitDuration);
          });
        },
        leave : () => {
        },
        enter : (data) => {
          ga('set', 'page', data['next']['url']['path']);
          ga('send', 'pageview');

          window.scrollTo(0, 0);
          this.checkPageApp(data);

          FONTPLUS.targetSelector('#wrapper')
          FONTPLUS.reload(false);

          setTimeout(() => {
            this.mainApp.initPageApp(this.currentNamespace);
          }, 50);

          this.currentNamespace = data['next']['namespace'];
        }
      }]
    });
    window.appRouter = barba;
  }

  go(target) {
    barba.go(target);
  }

  checkPageApp(data) {
    let nextNamespace = data.next.namespace;
    // console.log('Router.checkPageApp', nextNamespace);
    if(nextNamespace == 'sample') {
      console.log('check grid');
      if($('.DummyGrid').css('display') == 'none') {
        $('.DummyGrid').css('display', 'flex');
      }
    } else {
      if($('.DummyGrid').css('display') == 'flex') {
        $('.DummyGrid').css('display', 'none');
      }
    }
  }



}

export default Router;
