class VideoThumbnail {
  constructor($el) {
    this.$el              = $el;
    this.video            = this.$el.children('video')[0];
    this.onLoadComplete   = false;

    this.video.addEventListener('loadeddata', () => {
      this.onLoadComplete = true;
    });
  }

  play() {
    if(this.onLoadComplete) {
      this.video.play();
      this.$el.addClass('-fadein');
    } else {
      this.waitInterval = setInterval(() => {
        if(this.onLoadComplete) {
          clearInterval(this.waitInterval);
          this.video.play();
          this.$el.addClass('-fadein');
        }
      }, 1000 / 30);
    }

  }
}

export default VideoThumbnail;
