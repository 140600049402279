/* global $ */

class ScrollBlock {
  constructor($container) {
    this.$container               = $container;
    this.mediaQuery               = window.matchMedia('(max-width: 750px)');
    this.isInitialResize          = false;
    this.onCompleteInitialResize  = false;
    this.$slider                  = this.$container.find('.ScrollBlockSlider');
    this.contentsRepeatPos        = 0;
    this.contentsPos              = 0;
    this.loadedVideoCount         = 0;

    this.isStartUpdate            = false;
    this.onLoadCompleteVideo      = false;
    this.contents                 = [];
    this.videos                   = [];

    this.$container.find('.ScrollBlockSlider__content').each((i, e) => {
      this.contents.push($(e));
    });

    this.$container.find('video').each((i, e) => {
      this.videos.push(e);
      e.addEventListener('loadeddata', () => {
        this.loadedVideoCount++;
      });
    });

    // 全てのvideoの読み込み完了を監視
    this.loadWaitInterval = setInterval(() => {
      if(this.videos.length == this.loadedVideoCount) {
        clearInterval(this.loadWaitInterval);
        this.onLoadCompleteVideo = true;
        this.onWindowResize();
      }
    }, 1000 / 30);

    $(window).on('resize', () => {
      this.onWindowResize();
    });
    this.onWindowResize();
  }

  setContentsPosition() {
    for(let i = 0; i < this.contents.length; i++) {
      if(i > 0) {
        this.contentsRepeatPos = parseInt(this.contents[i - 1].width() + 5);
        this.contents[i].css('left', this.contentsRepeatPos);
      }
    }
  }

  update() {
    this.contentsPos += 0.5;
    if(this.contentsPos >= this.contentsRepeatPos) {
      this.contentsPos = 0;
    }

    this.$slider.css('transform', `translate3d(-${this.contentsPos}px, 0, 0)`);
  }

  destroy() {
    clearInterval(this.updateInterval);
  }

  onWindowResize() {
    if(this.mediaQuery.matches) {
      if(!this.isInitialResize && this.onLoadCompleteVideo) {
        this.isInitialResize = true;
        setTimeout(() => {
          this.onCompleteInitialResize = true;
          this.$container.css('height', parseInt(this.contents[0].height() + 2));
          this.setContentsPosition();
          this.onWindowResize();
          this.$slider.css('opacity', 1);

        }, 500);
      }

      if(this.onCompleteInitialResize) {
        this.$container.css('height', parseInt(this.contents[0].height() + 2));
        if(!this.isStartUpdate) {
          this.isStartUpdate = true;
          clearInterval(this.updateInterval);
          this.updateInterval = setInterval(() => {
            this.update();
          }, 1000 / 60);
        }
      }
    } else {
      this.isStartUpdate = false;
      clearInterval(this.updateInterval);
    }
  }
}

export default ScrollBlock;
