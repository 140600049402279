/* global $ */

class News {
  constructor() {
    this.isLoadCompleteXml = false;

    $.ajax({
      url: '/news.xml',
      type: 'GET',
      dataType: 'XML',
      cache: false,
      success: (data) => {
        this.checkData(data);
      },
      // 失敗時の処理
      error: (err) => {
        console.log('XML Error');
        console.log(err);
      }
    });
  }
  checkData(xml) {
    $(xml).find('item').each((i, e) => {
      let _title = $(e).find('title').text();
      let _date = $(e).find('date').text();
      let _html = $(e).find('body').text();

      let htmlText = `<li class="NewsListItem">
      <button class="NewsListItemHead">
          <div class="NewsListItem__date">${_date}</div>
          <div class="NewsListItem__title">${_title}</div>
      </button>
      <div class="NewsListItemBody">
          <div class="NewsListItemBody__inner">
              <div class="NewsListItemBody__content">
                  <p>${_html}</p>
                  <button class="NewsListItemBody__close">閉じる</button>
              </div>
          </div>
      </div>
  </li>`;
      $('.NewsList').append($(htmlText));
    });
    this.isLoadCompleteXml = true;

  }
}

export default News;
