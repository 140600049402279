/* global $ */

class Header {
  constructor() {
    this.$container = $('.Header');
    this.navBtns = [];

    this.$container.find('.MenuListItemBtn').each((i, e) => {
      this.navBtns.push($(e));
    });
  }
  setMenu(target) {
    for(let i = 0; i < this.navBtns.length; i++) {
      if(this.navBtns[i].attr('data-namespace') == target) {
        this.navBtns[i].addClass('-current');
      } else {
        this.navBtns[i].removeClass('-current');
      }
    }
  }
}
export default Header;
