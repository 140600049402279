/* global $ */

class Hero {
  constructor(mainApp) {

    this.mainApp              = mainApp;
    this.$container           = $('.Hero');
    this.$title               = this.$container.find('.Hero__title');
    this.$lead                = this.$container.find('.Hero__lead');

    this.isHome               = false;
    this.mediaQuery           = window.matchMedia('(max-width: 1024px)');
    this.initialInnerHeight   = window.innerHeight;
    this.isFixedHeight        = false;

    if(this.mainApp.router.currentNamespace == 'home') {
      this.isHome = true;
    }

    this.onWindowResize();
    setTimeout(() => {
      this.$container.addClass('Hero--fadein');
      if(this.mediaQuery.matches) {
        this.$title.find('.InviewObj').addClass('fadein');
      }
    }, 100);
  }

  onWindowResize() {
    if(this.initialInnerHeight > window.innerHeight && !this.isFixedHeight) {
      this.isFixedHeight = true;
      this.initialInnerHeight = window.innerHeight;
    }
    if(this.isHome) {
      if(this.mediaQuery.matches) {
        $('.HeroImg').css('height', parseInt(this.initialInnerHeight - (this.$title.outerHeight(true) + this.$lead.outerHeight(true) + 36)));
      } else {
        $('.HeroImg').css('height', '100%');
      }
    } else {
      let $img_lazy = $('.HeroSecondaryImg').children('.img-lazy');
      if(this.mediaQuery.matches) {
        let bottomHeight = $('.SecondaryTitle').outerHeight(true) + 60;
        let secondaryImgHeight = parseInt(this.initialInnerHeight - bottomHeight);
        $('.HeroSecondaryImg').css('height', secondaryImgHeight);

        if($('.SecondaryCopy').length) {
          $img_lazy.css('height', parseInt(secondaryImgHeight - ($('.SecondaryCopy').height() / 2 + 60)));
        }

      } else {
        $img_lazy.css('height', '100%');
        $('.HeroSecondaryImg').css('height', '100%');
      }
    }
  }

  onWindowScroll(scrollTop) {
    if(scrollTop > parseInt(this.mainApp.windowHeight * 0.5)) {
      this.$title.addClass('Hero__title--fadeout');
    } else {
      this.$title.removeClass('Hero__title--fadeout');
    }
  }
}
export default Hero;
