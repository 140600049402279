import jQuery from 'jquery';
import 'jquery.easing';

const $ = jQuery;
window.jQuery = $;
window.$ = $;

import ImgLazyLoad from './modules/img-lazy-load';
import InviewObj from './modules/InviewObj';
import Router from './modules/Router';

import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import SpMenu from './modules/SpMenu';
import PageApp from './modules/PageApp';
import Header from './modules/Header';
import NewsListItem from './modules/NewsListItem';
import News from './modules/News';

class MainApp {
  constructor() {
    this.$window          = $(window);
    this.fxImgSet         = [];
    this.imgLazyLoad      = new ImgLazyLoad(this);
    this.spMenu           = new SpMenu(this);
    this.header           = new Header();
    this.news             = new News();

    $(document).on('keydown', (e) => {
      if(e.originalEvent.keyCode == 71) {
        if($('.DummyGrid').css('display') == 'none') {
          $('.DummyGrid').css('display', 'flex');
        } else {
          $('.DummyGrid').css('display', 'none');
        }
      }
    });

    /*
    if(!this.isTouchDevice()) {
    }
    */
    this.loadNewsInterval = setInterval(() => {
      if(this.news.isLoadCompleteXml) {
        clearInterval(this.loadNewsInterval);

        setTimeout(() => {
          $('.NewsListItem').each((i ,e) => {
            new NewsListItem($(e))
          });
        }, 100);

      }
    }, 1000 / 30);

    $(document).on('touchstart', 'a, button, .NewsListItem', (e) => {
      $(e.currentTarget).attr('data-touch', 'true');
    });

    $(document).on('mouseleave', 'a, button, .NewsListItem', (e) => {
      $(e.currentTarget).removeAttr('data-touch');
    });

    $('.BackToTopBtn').on('click', () => {
      this.scrollPage(0);
    });


    this.scrollableElement = null;
    let firefox = navigator.userAgent.match(/Firefox\/([0-9]+)\./);
    if ('scrollingElement' in document) {
      this.scrollableElement = document.scrollingElement;
    } else if (/*@cc_on!@*/false || (!!window.MSInputMethodContext && !!document.documentMode)) {
      this.scrollableElement = document.documentElement;
    } else if (firefox && parseInt(firefox[1]) <= 47) {
      this.scrollableElement = document.documentElement;
    } else {
      this.scrollableElement = document.body;
    }

    this.router = new Router(this);

    this.$window
      .on('resize', ()=>{
        this.onWindowResize();
      })
      .on('scroll', ()=>{
        this.onWindowScroll();

      });
    this.onWindowResize();
  }
  destroy() {
    this.fxImgSet = null;
    this.fxImgSet = [];
    this.imgLazyLoad.clear();

    if(this.pageApp != undefined) {
      this.pageApp.destroy();
      this.pageApp = null;
      this.pageApp = undefined;
    }

  }

  initPageApp(namespace) {
    this.spMenu.fadeout();
    this.spMenu.setMenu(namespace);
    this.header.setMenu(namespace);

    this.pageApp = new PageApp(this);

    this.findElement = $('div[data-barba="container"]');
    this.findElement.find('.InviewObj').each((i, e) => {
      this.fxImgSet.push(new InviewObj($(e)));
    });

    this.imgLazyLoad.clear();
    this.imgLazyLoad.init();

    this.onWindowResize();

    if(namespace == 'home' && location.hash == '#news') {
      setTimeout(() => {
        this.scrollPage(parseInt($('.News').offset()['top']));
      }, 400);

    }
  }

  isTouchDevice() {
    return ('ontouchstart' in window || navigator.msPointerEnabled) ? true : false;
  }

  scrollPage(val, isCancelTransition = false) {
    if(isCancelTransition) {
      $(this.scrollableElement).stop(false).scrollTop(val);
    } else {
      $(this.scrollableElement).stop(false).animate(
        {'scrollTop': val},
        1200,
        'easeInOutExpo'
      );
    }
  }

  onWindowResize() {
    this.windowWidth = this.$window.width();
    this.windowHeight = this.$window.height();

    if(this.pageApp != undefined) {
      this.pageApp.onWindowResize();
    }

    this.onWindowScroll();
  }

  onWindowScroll() {
    this.scrollTop = this.$window.scrollTop();

    if(this.pageApp != undefined) {
      this.pageApp.onWindowScroll(this.scrollTop);
    }

    if(this.fxImgSet) {
      for(let i = 0; i < this.fxImgSet.length; i++) {
        this.fxImgSet[i].update(this.scrollTop, this.windowHeight);
      }
    }

    this.imgLazyLoad.update(this.scrollTop, this.windowHeight);
  }
}

$('document').ready(() => {
  new MainApp();
});


$(window).on('load', () => {
  // window.onLoadComplete = true;
});
