/* global $ */

import ContentSwitcher from "./ContentSwitcher";
import Hero from "./Hero";
import ModalManager from "./ModalManager";
import ScrollBlock from "./ScrollBlock";

class PageApp {
  constructor(mainApp) {
    this.mainApp          = mainApp;
    this.contentSwitcher  = undefined;
    this.modalManager     = undefined;
    this.hero             = undefined;
    this.scrollBlockSet   = [];

    if($('.ContentSwitcher').length > 0) {
      this.contentSwitcher = new ContentSwitcher(this.mainApp);
    }
    if($('.Modal').length > 0) {
      this.modalManager = new ModalManager(this.mainApp);
    }
    if($('.Hero').length > 0) {
      this.hero = new Hero(this.mainApp);
    }
    if($('.ScrollBlock').length > 0) {
      $('.ScrollBlock').each((i, e) => {
        this.scrollBlockSet.push(new ScrollBlock($(e)));
      });
    }
  }
  onWindowResize() {
    if(this.hero) {
      this.hero.onWindowResize();
    }
  }
  onWindowScroll(scrollTop) {
    if(this.contentSwitcher) {
      this.contentSwitcher.onWindowScroll(scrollTop);
    }
    if(this.hero) {
      this.hero.onWindowScroll(scrollTop);
    }
  }
  destroy() {
    if(this.scrollBlockSet.length > 0) {
      for(let i = 0; i < this.scrollBlockSet.length; i++) {
        this.scrollBlockSet[i].destroy();
      }
    }
  }
}

export default PageApp;
