/* global $ */

class SpMenu {
  constructor(mainApp) {
    this.mainApp = mainApp;
    this.$el = $('.SpMenu');
    this.isFadein = false;
    this.menuItems = [];
    this.$el.find('.MenuListItem').each((i, e) => {
      this.menuItems.push($(e));
    });
    this.$kv = this.$el.find('.SpMenu__kv');
    this.$title = this.$el.find('.SpMenu__title');

    this.navBtns = [];

    this.$el.find('.MenuListItemBtn').each((i, e) => {
      this.navBtns.push($(e));
    });

    $('.ToggleMenuBtn').on('click', () => {
      if(this.isFadein) {
        this.fadeout();
      } else {
        this.fadein();
      }
    });

    for(let i = 0; i < this.menuItems.length; i++) {
      this.menuItems[i].css('opacity', 0);
    }
    this.$kv.css('opacity', 0);
    this.$title.css('opacity', 0);


  }
  setMenu(target) {
    for(let i = 0; i < this.navBtns.length; i++) {
      if(this.navBtns[i].attr('data-namespace') == target) {
        this.navBtns[i].addClass('-current');
      } else {
        this.navBtns[i].removeClass('-current');
      }
    }
  }
  fadein() {
    $('body').addClass('open-sp-menu');
    this.isFadein = true;
    this.$el
      .stop(false)
      .css({'opacity':0, 'display':'block'})
      .animate({'opacity': 1}, 300, 'easeInOutSine');

    this.$kv.stop(false).delay(150).animate({'opacity': 1}, 600, 'easeInOutSine');
    this.$title.stop(false).delay(250).animate({'opacity': 1}, 600, 'easeInOutSine');

    setTimeout(() => {
      for(let i = 0; i < this.menuItems.length; i++) {
        this.menuItems[i].stop(false).delay(i * 40).animate({'opacity': 1}, 700, 'easeOutExpo');
      }
    }, 350);
  }
  fadeout() {
    $('body').removeClass('open-sp-menu');
    this.isFadein = false;
    this.$el.stop(false).animate({'opacity':0}, 300, 'easeOutSine', () => {
      this.$el.css('display', 'none');
      for(let i = 0; i < this.menuItems.length; i++) {
        this.menuItems[i].stop(false).css('opacity', 0);
      }
      this.$kv.stop(false).stop(false).css('opacity', 0);
      this.$title.stop(false).stop(false).css('opacity', 0);
    });

  }
}
export default SpMenu;
