/* global $ */

import Swiper, { Pagination, EffectFade } from 'swiper';

class Modal {
  constructor($container) {
    this.$container         = $container;
    this.$swiperContainer   = this.$container.find('.swiper');
    this.target             = this.$container.attr('data-target');
    this.$pagination        = this.$container.find('.ModalPagination');
    this.$btnClose          = this.$container.find('.ModalCloseBtn');
    this.mediaQuery         = window.matchMedia('(max-width: 750px)');
    this.paginationBtns     = [];

    this.$btnClose.on('click', () => {
      this.fadeout();
    });

    this.swiper = new Swiper('#' + this.$swiperContainer.attr('id'), {
      modules: [Pagination, EffectFade],
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      on: {
        activeIndexChange: () => {
          this.setNavPosition();
        }
      },
      pagination: {
        el: '.ModalPagination',
        clickable: true,
        bulletClass: 'ModalPaginationBtn',
        renderBullet: (index, className) => {
          let _index = String(index + 1);
          if(_index.length == 1) _index = '0' + _index;
          return '<button class="' + className + '"><img src="/assets/images/architecture/' + this.target + '_' + _index + '.jpg"></button>';
        },
      },
    });
  }
  setNavPosition() {
    if(this.mediaQuery.matches) {
      if(this.paginationBtns.length == 0) {
        this.$pagination.find('.ModalPaginationBtn').each((i, e) => {
          this.paginationBtns.push($(e));
        });
      }

      let left = this.paginationBtns[this.swiper.activeIndex].position()['left'];
      let width = this.paginationBtns[this.swiper.activeIndex].width();

      this.$pagination.css('transform', `translate(${-parseInt(left + (width / 2))}px, 0px)`);
    } else {
      let totalHeight = (92 + 16) * this.swiper.activeIndex;
      this.$pagination.css('transform', `translate(0, ${-parseInt(totalHeight)}px)`);
    }
  }

  fadein(index) {
    console.log('open modal id:' + this.target + ', index:' + index);
    this.$container.stop(false).css({'opacity':0, 'display':'block', 'pointer-events': 'auto'});
    this.swiper.slideTo(index, 0);
    this.setNavPosition();
    setTimeout(() => {
      this.swiper.update();
      this.$container.animate({'opacity':1}, 500, 'linear');
    }, 30);
  }
  fadeout() {
    this.$container.stop(false).animate({'opacity':0}, 400, 'linear', () => {
      this.$container.css('display', 'none');
    });

  }
}
class ModalManager {
  constructor(mainApp) {
    this.mainApp = mainApp;
    this.modalSet = [];

    $('.Modal').each((i, e) => {
      this.modalSet.push(new Modal($(e)));
    });

    $('.GalleryItem').on('click', (e) => {
      this.open($(e.currentTarget).attr('data-gallery'), parseInt($(e.currentTarget).attr('data-index')));
    });

    setTimeout(() => {
      $('.Modal').css('display', 'none');
    }, 1000);

  }
  open(targetModal, index) {
    for(let i = 0; i < this.modalSet.length; i++) {
      if(this.modalSet[i].target == targetModal) {
        this.modalSet[i].fadein(index);
        break;
      }
    }
  }

}
export default ModalManager;
